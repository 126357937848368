import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';

import HeroTitle from '../../components/HeroTitle';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      if (typeof this.props.onChange === 'function') {
        this.props.onChange(nextProps.name, nextProps.value);
      }
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  costChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ vatValue: parseInt(e.target.value) * 0.2 });
    this.setState({
      totalValue: (
        parseFloat(e.target.value) + parseFloat(e.target.value * 0.2)
      ).toFixed(2),
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout>
        <HeroTitle title="Build a quote" />
        <div className="strip">
          <div className="row">
            <div className="formLayout">
              <div className="formLayout__form">
                <form
                  name="Build a quote"
                  method="post"
                  action="/build-a-quote/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <fieldset>
                    <legend>Client Details</legend>
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field required">
                      <label className="label" htmlFor={'client-name'}>
                        Client name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'clientName'}
                          onChange={this.handleChange}
                          id={'client-name'}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="field required">
                      <label className="label" htmlFor={'client-email'}>
                        Client email address
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'email'}
                          name={'clientEmail'}
                          onChange={this.handleChange}
                          id={'client-email'}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="field required">
                      <label className="label" htmlFor={'job-ref'}>
                        Job Reference
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'jobReference'}
                          onChange={this.handleChange}
                          id={'job-ref'}
                          required={true}
                        />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label" htmlFor={'date-valid'}>
                        Quote valid until:
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'date'}
                          name={'quoteValidUntil:'}
                          onChange={this.handleChange}
                          id={'date-valid'}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>Job Details</legend>
                    <div className="field">
                      <label className="label" htmlFor={'job-location'}>
                        Location
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'jobLocation'}
                          onChange={this.handleChange}
                          id={'job-location'}
                        />
                      </div>
                    </div>
                    <div className="field required">
                      <label className="label" htmlFor={'job-details'}>
                        Other Details
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={'jobDetails'}
                          onChange={this.handleChange}
                          id={'job-details'}
                          rows="6"
                          required={true}
                        />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>Total costs</legend>
                    <div className="field required">
                      <label className="label" htmlFor={'net-value'}>
                        Net (£)
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'number'}
                          name={'netValue'}
                          onChange={this.costChange}
                          id={'net-value'}
                          required={true}
                          min={0.01}
                          step={0.01}
                          placeholder={0.0}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'vat-value'}>
                        VAT (@ 20%)
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'number'}
                          name={'vatValue'}
                          id={'vat-value'}
                          required={true}
                          min={0.01}
                          step={0.01}
                          placeholder={0.0}
                          readOnly={true}
                          value={(this.state.netValue * 0.2).toFixed(2) || 0}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'total-value'}>
                        Total (£)
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'number'}
                          name={'totalValue'}
                          id={'total-value'}
                          required={true}
                          min={0.01}
                          step={0.01}
                          placeholder={0.0}
                          readOnly={true}
                          value={
                            (
                              parseFloat(this.state.netValue) +
                              parseFloat(this.state.netValue * 0.2)
                            ).toFixed(2) || 0
                          }
                        />
                      </div>
                    </div>
                  </fieldset>
                  <div className="field">
                    <button type="submit">Send quote to client</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
